import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/weather-background.jpg';
import metaImage from './assets/meta.jpg';

const WeatherPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-weather"
      helmet={{
        title: 'TileDB - Manage massive weather datasets and scale predictive models',
        description: 'Boost performance over large NetCDF weather datasets using TileDB alongside xarray and your favorite tools.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Weather"
      title="Manage massive weather datasets and scale predictive models"
      heroImage={BackgroundImage}
      icon={dataTypes.weather.icon}
      overview={
        <>
          <p>
            Moving enormous weather datasets to and from the cloud for analysis creates data management challenges and immense storage
            costs. Storing data in flat files on inexpensive cloud object storage makes it difficult to discover and analyze multi-terabyte
            datasets.
          </p>
          <p>
            TileDB provides a cloud-native solution that is superior to NetCDF, which is designed for efficient data analysis on cloud
            object storage. With support for time-series data, TileDB lets you easily experiment with diverse and ever-growing datasets. In
            addition, TileDB interoperates with popular data science tools like Dask and xarray. Take advantage of TileDB’s compression and
            scalable, serverless computational engine to enjoy huge TCO savings.
          </p>
        </>
      }
      relatedItems={[industries.maritime, industries.earthObservation, industries.reinsurance, dataTypes.sar, dataTypes.ais]}
    />
  );
};

export default WeatherPage;
